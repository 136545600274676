@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #1d1d16;
}

.hue-color {
  color: red;
  background-image: -webkit-linear-gradient(92deg, red, yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s infinite linear;
}

a {
  text-decoration: none;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.header-color {
  /* background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); */
  background-image: url(/static/media/Background.2453c06f.jpg); /* Firefox 3.6+ */ /* Safari 3.1+ и Chrome 4.0+ */ /* Opera 9.6+ */
  background-size: 100%; /* Современные браузеры */
  background-repeat: no-repeat;
  background-size: cover;
  height: 8.625rem;
}

.header-second-row {
  min-height: 90px;
}

.header-second-row img {
  height: 150px;
  width: 150px;
  border-radius: 90px;
  border: solid 3px #ffffff;

  position: relative;
  top: 0%;
  transform: translateY(-50%);
}

.header-second-row .image {
  align-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 72px;
}

@media (min-width: 768px) {
  .header-second-row .image {
    align-items: flex-end;
    padding-top: 0px;
  }
}

.header-second-row .name {
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

@media (min-width: 768px) {
  .header-second-row .name {
    align-items: flex-start;
    padding-top: 0px;
  }
}

.header-second-row .name .row .col {
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 768px) {
  .header-second-row .name .row .col {
    align-items: flex-start;
    text-align: left;
  }
}

.header-second-row .availible {
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

@media (min-width: 992px) {
  .header-second-row .availible {
    align-items: flex-end;
    padding-top: 0px;
  }
}

h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.875rem;
  /* or 94% */

  color: #1d1d16;
}

h2 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 30px;
  text-align: center;
  /* identical to box height, or 94% */

  /* FlowBlack */

  color: #1d1d16;
}

h3 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin: 0px;
}

.info {
  padding-top: 16px;
  padding-bottom: 24px;
}

.first-column {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
  display: flex;
  grid-row-gap: 24px;
  row-gap: 24px;
  flex-direction: column;
}

.my-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  width: 100%;

  background: #ffffff;
  border: 5px solid #1d1d16;
  box-sizing: border-box;
  border-radius: 22px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.card-title {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin: 0px;
  padding-bottom: 24px;
  display: flex;
  grid-column-gap: 10px;
  column-gap: 10px;
  flex-direction: row;
}

.card-title img {
  width: 32px;
  height: 32px;
}

img.sm-icon {
  width: 25px;
  height: 25px;
  margin-right: 6px;
  margin-left: 6px;
}

.my-card .card-content {
  display: flex;
  grid-row-gap: 10px;
  row-gap: 10px;
  flex-direction: column;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
}

.my-card .card-content .line {
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
}

.bold {
  font-weight: bold;
  /* identical to box height, or 150% */
}

span.medium {
  font-weight: 500;
}
p {
  margin-bottom: 8px;
  /* identical to box height, or 150% */
}

p.f16 {
  font-size: 1rem;
  /* identical to box height, or 150% */
}

.second-colum {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
  display: flex;
  grid-row-gap: 24px;
  row-gap: 24px;
  flex-direction: column;
}

.my-card .card-content ul {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding-left: 1rem;
}

.my-card .card-content ul li {
  padding-bottom: 10px;
}

.my-card .card-content ul li:last-child {
  padding-bottom: 0px;
}

.my-card.card-projet .card-content {
  display: flex;
  grid-row-gap: 34px;
  row-gap: 34px;
}

.btn {
  border: 4px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0px 3px 0px #1d1d16;
  border-radius: 22px;
  padding: 10px 3.125rem 9px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  transition: none;
}

.btn-primary {
  color: #1d1d16;
  background-color: #ffffff;
  border-color: #1d1d16;
  outline: none !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #ffffff;
  border-color: #1d1d16;
}

.btn-primary:focus {
  color: #1d1d16;
  border-color: #1d1d16;
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 0px #1d1d16;
  transition-duration: 0.1s;
  transition-property: background-color, color, box-shadow, transform;
}

.btn-primary:hover {
  color: #1d1d16;
  border-color: #1d1d16;
  background: #fff;
  box-shadow: 0px 3px 0px #1d1d16;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ffffff;
  border-color: #1d1d16;
}

.btn-primary:active {
  color: #1d1d16;
  border-color: #1d1d16;

  background: rgb(255, 255, 255);
  box-shadow: none;
  transition-duration: 0.05s;

  transform: translateY(3px);
  transition-property: background-color, color, box-shadow, transform;
}

.modal-content {
  border: none;
}

.projets {
  margin-bottom: 24px;
}

.contact {
  margin-bottom: 24px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1d1d16;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  transition-property: background-color, color, box-shadow, transform;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 3px solid #1d1d16;
  border-radius: 0;
  transition-duration: 0.1s;
}

.form-label {
  margin-bottom: 6px;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.form-control:focus {
  color: #1d1d16;
  border-color: #1d1d16;
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 0px #1d1d16;
  transition-duration: 0.01s;
  transform: translateY(-3px);
  transition-property: background-color, color, box-shadow, transform;
}

.form-control:hover {
  color: #1d1d16;
  border-color: #1d1d16;
  background: #fff;
  box-shadow: 0px 3px 0px #1d1d16;
  transition-duration: 0.1s;
  transform: translateY(-3px);
  transition-property: background-color, color, box-shadow, transform;
}

.form-control:active {
  color: #1d1d16;
  border-color: #1d1d16;
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 0px #1d1d16;
  transition-duration: 0.01s;
  transition-property: background-color, color, box-shadow, transform;
}
@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

body { /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
  animation: fade-in 1s linear; /* IE 10+, Fx 29+ */
}
.modal-toast-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  font-size: 1.4rem;

  width: 100%;

  background: #ffffff;
  border: 5px solid #1d1d16;
  box-sizing: border-box;
  border-radius: 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.form-control:valid {
  background-color: #ffffff !important;
}

.form-control:invalid {
  background-color: #ffffff !important;
}

input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
          appearance: menulist-button;
  background-image: none !important;
  background-color: #ffffff;
  color: #ffffff;
}

.appear { /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
  animation: fade-in 1s linear; /* IE 10+, Fx 29+ */
}


